<template>
  <div class="card">
    <SubTitle :title="'上门资料'"> </SubTitle>
    <div class="context">
      <div class="card">
        <div class="section" v-for="(attach, index) in attachmentList" :key="index">
          <span class="title">{{ attach.name }}</span>
          <span class="action" v-if="attach.files.length" @click="viewFile(attach)">查看</span>
          <span class="action empty" v-else></span>
        </div>
        <van-empty v-if="attachmentList.length == 0" description="暂无附件" />
      </div>
    </div>

    <SubTitle title="证据资料"></SubTitle>
    <div class="content">
      <div class="form">
        <div>
          <div class="picture-section">
            <div class="title">户籍地资料</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files1" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">工作地资料</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files2" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">居住地资料</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files3" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">房产地资料</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files8" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">家访对象资料</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files4" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">客户回款凭证</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files5" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">车辆照片</div>
            <div class="pictures">
              <Uploader accept="image/*,video/*,audio/*" v-model="form.files6" :readonly="readonly" />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">通话录音/视频</div>
            <div class="pictures">
              <Uploader accept="video/*,audio/*" v-model="form.files7" :readonly="readonly" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!readonly" class="actions">
      <template>
        <van-button type="primary" class="flex1" :loading="pending" @click="handleSubmit()">保存</van-button>
      </template>
      <template>
        <van-button type="info" class="flex1" :loading="pending" @click="openPopup('家访结案')"
          :disabled="fileList.length == 0">提交结案</van-button>
      </template>
    </div>

    <PopupSlot v-model="showPopup" :actionType="actionType" @closePopup="closePopup" @confirmPopup="confirmPopup">
      <van-form ref="refForm">
        <template>
          <van-field required name="visitEffective" label="是否有效：" class="form-no-boder"
            :rules="[{ required: true, message: '请选择' }]">
            <template #input>
              <van-radio-group v-model="popForm.visitEffective" direction="horizontal">
                <van-radio v-for="item in defaultEnum" :name="item.value" :key="item.value">{{ item.displayName
                }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-if="popForm.visitEffective == '1'" readonly required clickable label="有效类型："
            :value="popForm.effectiveTypeText" placeholder="请选择" :rules="[{ required: true, message: '请选择' }]"
            @click="setShowType(true)" />
          <van-field required :label="`家访情况：`" v-model="popForm.visitResultExt" :placeholder="`请输入`"
            :rules="[{ required: true, message: '请输入家访情况' }]"></van-field>
        </template>

        <van-popup v-model="showPicker" round position="bottom" @close="closePicker">
          <van-picker show-toolbar :columns="listType" @confirm="onConfirmType" />
        </van-popup>
      </van-form>
    </PopupSlot>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import storage from "@/libs/storage";
import Vue from "vue";
import * as _ from "lodash";
import { Empty, Toast } from "vant";
import Api from "@/api/caseHandle";

Vue.use(Empty);
const DEFAULT_ENUM = [{
  value: '1',
  displayName: '是'
}, {
  value: '0',
  displayName: '否'
}]
const DEFAULT_FORM = {
  assignNo: "",
  files1: [],
  files2: [],
  files3: [],
  files4: [],
  files5: [],
  files6: [],
  files7: [],
  files8: [],
};
const DEFAULT_POP_FORM = {
  visitEffective: "", //家访是否有效
  effectiveType: "", //家访有效类型
  effectiveTypeText: "",
  visitResultExt: "", //家访结案情况描述
}

export default {
  props: {
    detailInfo: {
      type: Object,
      required: true,
    },
    homeNo: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      defaultEnum: DEFAULT_ENUM,
      actionType: "家访结案",
      showPopup: false,
      popForm: {
        ..._.cloneDeep(DEFAULT_POP_FORM),
      },
      showPicker: false,

      attachmentFiles: this.detailInfo?.assignRecordDTO?.assignFiles || {},
      incomingFiles: this.detailInfo?.assignRecordDTO?.assignFiles2 || {},
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },
      pending: false,
    };
  },
  computed: {
    attachmentList() {
      let source = { ...this.attachmentFiles, ...this.incomingFiles }
      return Object.keys(source || {}).map((name) => {
        return {
          name,
          files: source[name] || [],
        };
      });
    },
    listType() {
      return (this.getEffectiveTypeEnum || []).map(e => ({ ...e, text: e.displayName }));
    },
    fileList() {
      const params = _.cloneDeep(this.form);
      const fileList = [
        ...params.files1,
        ...params.files2,
        ...params.files3,
        ...params.files4,
        ...params.files5,
        ...params.files6,
        ...params.files7,
        ...params.files8,
      ];
      return fileList;
    },
  },
  methods: {
    viewFile(attach) {
      var storeId = uuidv4();
      storage.setSession(storeId, attach);
      this.$router.push({
        name: "FileViewer",
        query: {
          storeId,
        },
      });
    },

    // 弹出层打开
    async openPopup(action) {
      this.actionType = action;
      this.showPopup = true;
    },
    // 关闭弹出层
    closePopup() {
      this.$set(this, "popForm", { ...DEFAULT_POP_FORM });
      this.$refs["refForm"].resetValidation();
      this.closePicker();
    },
    closePicker() {
      this.setShowType(false)
    },
    // 弹出层确认
    async confirmPopup() {
      try {
        await this.$refs["refForm"].validate();
        this.finishApply2()
      } catch (error) {
        console.log(error);
      }
    },
    setShowType(val) {
      this.showPicker = val;
    },
    // 选择确认
    onConfirmType(value) {
      this.$set(this.popForm, "effectiveType", value.value);
      this.$set(this.popForm, "effectiveTypeText", value.displayName);
      this.setShowType(false)
    },

    // 提交证据资料
    async handleSubmit() {
      try {
        Toast.fail(`请至少上传一个附件`);

        const loading = Toast.loading();
        const { detailInfo, fileList } = this;
        const params = _.cloneDeep(this.form);

        if (fileList.length == 0) {
          Toast.fail(`请至少上传一个附件`);
          return;
        }
        Object.assign(params, {
          assignNo: detailInfo.assignNo,
        });
        this.pending = true
        const res = await Api.evidenceAttach(params);
        loading.close();
        Toast.success(res.message);
      } catch (error) {
        Toast.fail(error);
      } finally {
        this.pending = false
      }
    },

    // 结案
    async finishApply2() {
      let { popForm, detailInfo } = this;
      Toast.loading();
      try {
        let params = {
          assignNo: detailInfo.assignNo,
          visitEffective: popForm.visitEffective, //家访是否有效
          effectiveType: popForm.visitEffective == '1' ? popForm.effectiveType : '', //家访有效类型
          visitResultExt: popForm.visitResultExt, //家访结案情况描述
        }
        this.pending = true
        const res = await Api.finishApply2(params);

        Toast.success(res.message);
        this.showPopup = false;
        // this.$router.go(-1);
        this.$router.push({ name: 'List', query: { statusEq: 'PROCESSING' } });
      } catch (error) {
        Toast.fail(error);
      } finally {
        this.pending = false
      }
    },

    // 家访反馈信息详情
    async getFinishApply(assignNo) {
      try {
        const res = await Api.getFinishApply(assignNo);
        let _data = res.data;
        Object.assign(this.form, _data, {
          files1: _data.files1 || [],
          files2: _data.files2 || [],
          files3: _data.files3 || [],
          files4: _data.files4 || [],
          files5: _data.files5 || [],
          files6: _data.files6 || [],
          files7: _data.files7 || [],
          files8: _data.files8 || [],
        });
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.fetchEnums("getEffectiveTypeEnum");
    this.getFinishApply(this.detailInfo.assignNo);
  },
};
</script>
<style lang="less" scoped>
.card {
  padding: 8px 8px 20px 8px;

  .section {
    background: #fff;
    color: #333333;
    padding: 8px 0px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 650;
    }

    .action {
      font-size: 12px;
      cursor: pointer;
      color: #02a7f0;
    }
  }

  .context {
    padding: 10px 10px;
    background-color: #fff;
  }

  .content {
    padding-bottom: 40px;
    flex: 1;
    overflow-y: auto;

    .customer {
      margin: 5px 5px;
    }

    .form {
      padding-top: 10px;
      margin-bottom: 20px;
      background: #fff;
    }

    .divder-big {
      height: 10px;
      background: #f2f2f2;
    }

    .divder {
      height: 2px;
      background: #f2f2f2;
    }

    .picture-section {
      padding: 0px 10px;
      background: #fff;

      .title {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 8px;
      }
    }

    /deep/ .pictures {
      padding: 5px 0px;

      .van-uploader__upload {
        background: #f2f2f2;
      }
    }
  }

  .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding: 13px 13px;

    .van-button {
      border-radius: 3px;
      margin-right: 8px;
      height: 36px;
      line-height: 36px;

      &:last-child {
        margin-right: 0;
      }
    }

    .flex1 {
      flex: 1;
    }
  }
}
</style>
